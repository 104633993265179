<template>
  <div>
    <div class="container">
      <div class="symbol">Contact</div>
    </div>
    <div class="container1">
      <div class="textarea1">
        <textarea
          placeholder="Any Commnet I love it"
          v-model="contents"
        ></textarea>
        <button @click.prevent="post1()">Write</button>
      </div>
    </div>
    <div class="reply">
      <span> hi </span>
      <div class="time">2017.03.04</div>
    </div>
    <div class="reply">
      <span> adsfnalknsdlf </span>
      <div class="time">2017.03.04</div>
    </div>
    <div class="reply">
      <span> hi </span>
      <div class="time">asd2017.03.04</div>
    </div>
    <div class="reply">
      <span> hi </span>
      <div class="time">2017.03.04</div>
    </div>
    <div class="reply">
      <span> hi </span>
      <div class="time">2017.03.04</div>
    </div>
  </div>
</template>

<script>
const axios = require("axios");
export default {
  data() {
    return {
      contents: ""
    };
  },
  mounted() {
    console.log(this.$firebase);
  },
  methods: {
    post1() {
      axios
        .post("https://aboutme-4dfd7.firebaseio.com/record", {
          content: this.contents
        })
        .then(response => {
          console.log(response);
        });
    }
  }
};
</script>
npm
<style scoped>
.container {
  position: relative;
  margin: 0 auto;
  width: 13%;
  height: 80px;
  background-color: rgba(51, 153, 255, 0.2);
  box-shadow: 0px 0px 12px rgba(51, 153, 255, 0.5);
  border: 1px solid rgba(51, 153, 255, 0.25);
  margin-bottom: 30px;
}

.container .symbol {
  text-align: center;
  position: absolute;
  top: 10px;
  left: 0px;
  right: 0px;
  font-size: 50px;
  font-weight: bold;
  color: rgba(255, 255, 255, 0.75);
  text-shadow: 0 0 10px rgba(51, 153, 255, 1);
}
.reply {
  position: relative;
  width: 30%;
  height: 30px;
  background-color: rgba(255, 153, 255, 0.5);
  box-shadow: 0px 0px 12px rgba(255, 153, 255, 0.5);
  border: 1px solid rgba(255, 153, 255, 0.25);
  margin: 0 auto;
  margin-top: 30px;
  text-align: center;
  line-height: 30px;
  font-weight: bold;
}
.time {
  position: absolute;
  right: 0;
  /* height: 100%; */
  font-size: 11px;
  display: inline-block;
  font-weight: initial;
}

.container1 {
  padding: 30px;
  position: relative;
  margin: 0 auto;
  width: 30%;
  border-radius: 5px;
  height: 44px;
  background-color: rgba(51, 153, 255, 0.5);
  box-shadow: 0px 0px 12px rgba(51, 153, 255, 0.5);
  border: 1px solid rgba(51, 153, 255, 0.25);
}
.textarea1 {
  position: relative;
}
.textarea1 textarea {
  border: none;
  background: transparent;
  width: 100%;
  resize: none;
  outline: none;
  border-bottom: 2px solid grey;
  font-size: 15px;
  height: 18px;
}

.textarea1 textarea::placeholder {
  color: antiquewhite;
}

.textarea1 textarea:focus {
  color: white;
  border-bottom: 2px solid black;
}
.textarea1 button {
  right: 0;
  position: absolute;
  background-color: rgba(51, 153, 255, 0.5);
  border-color: rgba(51, 153, 255, 0.25);
  cursor: pointer;
}
.textarea1 button:hover {
  background-color: rgba(51, 153, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.75);
}
</style>
